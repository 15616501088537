.skeleton {
  position: relative;
  overflow: hidden;
  background-color: #e2e8f0;
}

.skeleton::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 3s infinite ease-in-out;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
